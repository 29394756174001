import { RESET_STATE, SET_CHALLENGE_ID, SET_ERROR, SET_VERIFICATION_TYPE } from './types';
import promisePoller from 'promise-poller';
import axios from 'axios';
import { VERIFICATION_TYPES } from '@/constants/verification';
import i18n from '../../../locales/i18n';

const TIMEOUT_POLLING_FOR_MOBILE_VERIFICATION = 1000;

const pollingFn = (store, externalId, method) => () => {
  return store.dispatch('signing/getSigningStatus', {
    externalId,
    body: { method },
  }, { root: true });
};

const poll = async (store, method) => {
  const externalId = store.rootGetters['processStep/externalId'];

  try {
    const result = await promisePoller({
      taskFn: pollingFn(store, externalId, method),
      interval: TIMEOUT_POLLING_FOR_MOBILE_VERIFICATION,
      shouldContinue: (err, value) => !err && value?.data?.status === 'RUNNING',
    });
    if (result?.data?.status === 'COMPLETED_FAILURE') {
      store.dispatch('handleError', { response: { data: { error_code: result.data.state }}});
    } else {
      await axios.post(`/api/v1/application/${externalId}/signing/finalize`, { method });
      store.dispatch('processStep/updateProcessStep', {
        application: {},
        variables: {
          signingMethod: {
            type: 'string',
            value: method === 'MOBILE_SIGNATURE' ? 'mobile_id' : method.toLowerCase(),
          },
          isContractSigned: {
            type: 'Boolean',
            value: true,
          },
        },
      }, { root: true });
    }
  } catch (error) {
    store.dispatch('handleError', error.length ? error[0] : error);
  }
};

const initiateSigning = async (store, verificationType) => {
  const externalId = store.rootGetters['processStep/externalId'];
  store.commit(RESET_STATE);

  const method = {
    [VERIFICATION_TYPES.smart_id]: 'SMART_ID',
    [VERIFICATION_TYPES.mobile_id]: 'MOBILE_SIGNATURE',
  }[verificationType];

  if (!method) {
    return store.dispatch('handleError', new Error('Unexpected signing method'));
  }

  store.commit(SET_VERIFICATION_TYPE, verificationType);

  try {
    const signingResponse = await store.dispatch('signing/initiateSigning', {
      externalId,
      body: {
        method,
        displayText: i18n.t('components.signingModal.displayText'),
        type: 'PDF',
        countryCode: 'LT',
      },
    }, { root: true });
    const verificationCode = signingResponse.data && signingResponse.data.verificationCode;
    store.commit(SET_CHALLENGE_ID, verificationCode);
    store.dispatch('poll', method);
  } catch (e) {
    store.dispatch('handleError', e);
  }
};

const handleError = async (store, error) => {
  store.commit(SET_ERROR, error);
};

export default {
  initiateSigning,
  poll,
  handleError,
};
