import get from 'lodash/get';

class TrackingData {
  getData () {
    const data = { userAgent: this.getUserAgent() };

    if (this.getTrackingId() && this.isGoogleAnalyticsEnabled()) {
      data.googleAnalyticsCustomerId = this.getGaClientId();
      data.googleAnalyticsSessionId = this.getGaSessionId();
      data.screenResolution = this.getGaScreenResolution();
      data.viewportSize = this.getGaViewportSize();
    }

    return data;
  }

  getGaClientId () {
    return this.getProperty('clientId');
  }

  /**
   * Retrieves the Google Analytics (GA) session ID from the 1st Party Cookies for GA4 server-side tracking.
   *
   * @returns {string|undefined} The GA session ID if found in the cookies, or undefined if not found.
   */
  getGaSessionId () {
    return document.cookie.match(/_ga_[^=]+=([^;]+)/g)?.[0].split('.')[2];
  };

  getGaScreenResolution () {
    return this.getProperty('screenResolution');
  }

  getGaViewportSize () {
    return this.getProperty('viewportSize');
  }

  getTrackingId () {
    return get(window, 'config.GA_TRACKING_ID');
  }

  getUserAgent () {
    return navigator.userAgent;
  }

  getProperty (property) {
    const trackers = window.ga.getAll();
    let i, len;

    for (i = 0, len = trackers.length; i < len; i += 1) {
      if (trackers[i].get('trackingId') === this.getTrackingId()) {
        return trackers[i].get(property);
      }
    }
  }

  isGoogleAnalyticsEnabled () {
    return !!(window.ga && typeof window.ga.getAll === 'function');
  }
}

export default new TrackingData();
