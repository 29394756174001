export const CHANNEL = {
  SELF_SERVICE: 'SELF_SERVICE',
  WEBPAGE: 'WEBPAGE',
};
export const SOURCE = {
  BIGBANK: 'BIGBANK',
  AUTOPLIUS: 'Autoplius',
  DIGINET: 'Diginet',
  ARUODAS: 'Aruodas',
};

export const APPLICATION_ORIGIN = {
  WEB: 'WEB',
  PARTNER_LANDING_PAGE: 'PARTNER_LANDING_PAGE',
  PARTNER: 'PARTNER',
};

export const THEME = { AUTOPLIUS: 'autoplius' };

export const HOMEPAGE_URL = 'https://www.bigbank.lt';
export const CONTACT_PHONE_AUTOPLIUS = '8 700 80066';
export const CONTACT_PHONE_DIGINET = '+370 700 33030';
export const AUTOPLIUS_URL = 'https://autoplius.lt';
export const POST_CODE_HELP_URL = 'https://www.post.lt/pasto-kodu-ir-adresu-paieska';
export const NEGATIVE_DECISION_FAQ_URL = 'https://www.bigbank.lt/duk/#1-paskolos-gavimas/9-kodel-negavau-paskolos';

export const contact = {
  email: 'info@bigbank.lt',
  emailHL: 'bustas@bigbank.lt',
  phone: 1388,
};

export const MARITAL_STATUS = {
  married: 'MARRIED',
  single: 'SINGLE',
  divorced: 'DIVORCED',
  widow: 'WIDOW',
};

export const ID_DOCUMENT = {
  idCard: 'ID_CARD',
  passport: 'PASSPORT',
  permanentResidency: 'PERMANENT_RESIDENCE_PERMIT_CARD',
  euPermanentResidenceCard: 'EU_RESIDENCE_PERMIT_CARD',
  permanentResidencePermitDocument: 'PERMANENT_RESIDENCE_PERMIT_DOCUMENT',
  residenceStatusDocument: 'RESIDENCE_STATUS_DOCUMENT',
  temporaryResidencePermitDocument: 'TEMPORARY_RESIDENCE_PERMIT_DOCUMENT',
  digitalTemporaryResidencePermit: 'DIGITAL_TEMPORARY_RESIDENCE_PERMIT',
};

export const SPOUSE_ID_DOCUMENTS = {
  ...ID_DOCUMENT,
  temporaryResidencePermitCard: 'TEMPORARY_RESIDENCE_PERMIT_CARD',
};

export const LOAN_PURPOSE = {
  DAILY_SETTLEMENTS: 'DAILY_SETTLEMENTS',
  PURCHASING_MOTOR_VEHICLES: 'PURCHASING_MOTOR_VEHICLES',
  RENOVATING_HOME: 'RENOVATING_HOME',
  MEDICAL_SERVICES: 'MEDICAL_SERVICES',
  COMBINING_LOANS: 'COMBINING_LOANS',
  PURCHASING_HOME: 'PURCHASING_HOME',
  PURCHASING_RENTAL_PROPERTY: 'PURCHASING_RENTAL_PROPERTY',
  PURCHASING_OTHER_REAL_ESTATE: 'PURCHASING_OTHER_REAL_ESTATE',
  BUILDING_HOME: 'BUILDING_HOME',
  BUILDING_RENTAL_PROPERTY: 'BUILDING_RENTAL_PROPERTY',
  BUILDING_OTHER_REAL_ESTATE: 'BUILDING_OTHER_REAL_ESTATE',
};

export const taskDefinitionKeys = {
  submitPublicApplication: 'submitPublicApplication',
  SubmitPersonalDetails: 'SubmitPersonalDetails',
  UploadDocuments: 'UploadDocuments',
  UploadEmploymentDocuments: 'UploadEmploymentDocuments',
  UploadRefinancingDocuments: 'UploadRefinancingDocuments',
  UploadSpouseDocuments: 'UploadSpouseDocuments',
  UploadLeasingDocuments: 'UploadLeasingDocuments',
  UploadRealEstateDocuments: 'UploadRealEstateDocuments',
  SubmitAddress: 'SubmitAddress',
  SubmitSpousePersonalData: 'SubmitSpousePersonalData',
  SubmitSpouseAddress: 'SubmitSpouseAddress',
  SubmitSpouseFinanceData: 'SubmitSpouseFinanceData',
  SubmitBankDetailsAndAddress: 'SubmitBankDetailsAndAddress',
  SubmitBankDetails: 'SubmitBankDetails',
  SubmitBankAccountNumber: 'SubmitBankAccountNumber',
  ViewAndSignContractContentLoader: 'ViewAndSignContractContentLoader',
  ViewAndSignContract: 'ViewAndSignContract',
  ReadNegativeDecision: 'ReadNegativeDecision',
  ShowContractSigningNegativePage: 'ShowContractSigningNegativePage',
  DuplicateApplicationThankYouPage: 'DuplicateApplicationThankYouPage',
  ReadUndecidedDecision: 'ReadUndecidedDecision',
  UnexpectedDecisionOnContractGenerationThankYouPage: 'UnexpectedDecisionOnContractGenerationThankYouPage',
  SpouseIdentifiedAndDataSubmittedThankYouPage: 'SpouseIdentifiedAndDataSubmittedThankYouPage',
  AdditionalDataSubmittedThankYouPage: 'AdditionalDataSubmittedThankYouPage',
  DocumentUploadSkippedThankYouPage: 'DocumentUploadSkippedThankYouPage',
  DocumentsUploadedThankYouPage: 'DocumentsUploadedThankYouPage',
  UploadedRefinancingDocumentsThankYouPage: 'UploadedRefinancingDocumentsThankYouPage',
  EmploymentDocumentsUploadedThankYouPage: 'EmploymentDocumentsUploadedThankYouPage',
  SpouseFinanceDataSubmittedThankYouPage: 'SpouseFinanceDataSubmittedThankYouPage',
  SpouseDocumentsUploadedThankYouPage: 'SpouseDocumentsUploadedThankYouPage',
  LeasingDocumentsUploadedThankYouPage: 'LeasingDocumentsUploadedThankYouPage',
  CallCustomerThankYouPage: 'CallCustomerThankYouPage',
  ReadHousingLoanRejectedPage: 'ReadHousingLoanRejectedPage',
  WaitForHousingLoanDecisionThankYouPage: 'WaitForHousingLoanDecisionThankYouPage',
  WaitForHousingLoanDecisionThankYouPageConditionalPositive: 'WaitForHousingLoanDecisionThankYouPage_conditionalPositive',
  HousingLoanDocumentsUploadedThankYouPage: 'HousingLoanDocumentsUploadedThankYouPage',
  WhatsNextPageBankLink: 'WhatsNextPageBankLink',
  WhatsNextPage: 'WhatsNextPage',
  ReviewApproved: 'ReviewApproved',
  ReviewApprovedUpsale: 'ReviewApprovedUpsale',
  ReviewApprovedFinal: 'ReviewApprovedFinal',
  ReviewApprovedUpsaleFinal: 'ReviewApprovedUpsaleFinal',
  ReviewApprovedAdditionalCheck: 'ReviewApprovedAdditionalCheck',
  ReviewApprovedConditional: 'ReviewApprovedConditional',
  ReviewApprovedRefinanceConditional: 'ReviewApprovedRefinanceConditional',
  ReviewConditionalNegative: 'ReviewConditionalNegative',
  ReviewRejectedBringCoBorrower: 'ReviewRejectedBringCoBorrower',
  ReviewApprovedLeasing: 'ReviewApprovedLeasing',
  SubmitIncomesAndLiabilities: 'SubmitIncomesAndLiabilities',
  SpouseIncomesAndLiabilities: 'SpouseIncomesAndLiabilities',
  SubmitCoBorrowerIncomesAndLiabilities: 'SubmitCoBorrowerIncomesAndLiabilities',
  IdentifyOnline: 'IdentifyOnline',
  SelectProduct: 'SelectProduct',
  ReadConditionalPositiveOffer: 'ReadConditionalPositiveOffer',
  SubmitSpouseDetails: 'SubmitSpouseDetails',
  ChooseContinueOption: 'ChooseContinueOption',
  ChooseContinueOptionAfterReData: 'ChooseContinueOptionAfterReData',
  SubmitRealEstateInfo: 'SubmitRealEstateInfo',
  ApplicationSubmittedThankYou: 'ApplicationSubmittedThankYou',
  ConsentSignedThankYouPage: 'ConsentSignedThankYouPage',
  InformCustomerAboutHousingLoanOffer: 'InformCustomerAboutHousingLoanOffer',
  ProvideBasViaOpenBanking: 'ProvideBasViaOpenBanking',
  ProvideSpouseBasViaOpenBanking: 'ProvideSpouseBasViaOpenBanking',
  SubmitCoborrowerAddressAndFinancialData: 'SubmitCoborrowerAddressAndFinancialData',
};

export const pages = {
  createApplication: 'createApplication',
  login: 'login',
  loginCoApplicant: 'loginCoApplicant',
  completeRemoteIdentification: 'completeRemoteIdentification',
  completeOnlineSigning: 'completeOnlineSigning',
  completeLoginServiceIdentification: 'completeLoginServiceIdentification',
  autoplius: 'autoplius',
  diginet: 'diginet',
  start: 'start',
  unauthorized: 'unauthorized',
  sessionExpired: 'session-expired',
  maintenance: 'maintenance',
  error500: 'error500',
  error404: 'error404',
  error451: 'error451',
  noTask: 'noTask',
  noProcess: 'noProcess',
  spouseIdentification: 'spouseIdentification',
  continue: 'continue',
};

// Expected rules to handle offer rejection reasons stored in application.offer.decisionReasons.rules.name.
export const DECISION_REASONS_RULES = {
  CUSTOMER_MAX_AGE: 'customerMaxAge',
  MANUALLY_REJECTED: 'manuallyRejected',
  CUSTOMER_IS_WANTED: 'customerIsWanted',
  NO_PERSONAL_ID_MATCH: 'noPersonalIdMatch',
  INCORRECT_NAME_PROVIDED: 'incorrectNameProvided',
  ACTIVE_NEGATIVE_DECISION: 'activeNegativeDecision',
  CREDIT_RESTRICTED_BY_THIRD_PARTY: 'creditRestrictedByThirdParty',
  FOREIGN_INCOME_AND_RESIDENCE: 'foreignIncomeAndResidence',
  ADDRESS_ABROAD: 'addressAbroad',
  SCORE_UNDER_MIN_NON_EXISTING_CUSTOMER: 'scoreUnderMinNonExistingCustomer',
  CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT: 'customerMaxLoanSumBelowLimit',
  SPOUSE_CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT: 'spouse_customerMaxLoanSumBelowLimit',
  REFINANCING_HIGH_RISK: 'refinancingHighRisk',
  RECENT_LOAN_FROM_BIGBANK: 'recentLoanFromBigbank',
  PERSONS_REGISTRY_ERROR: 'personsRegistryError',
  INVALID_DOCUMENT: 'invalidDocument',
  CUSTOMER_MIN_AGE: 'customerMinAge',
  CUSTOMER_HAS_WRITE_OFF_LOAN_IN_BIGBANK: 'customerHasWriteOffLoanInBigbank',
  FORMER_CUSTOMER_COURT_BAILIFF_PROCEEDING: 'formerCustomerCourtBailiffProceeding',
  FORMER_CUSTOMER_COLLECTION_PROCEEDING: 'formerCustomerCollectionProceeding',
  EXISTING_LOAN_IN_DEBT_COLLECTION: 'existingLoanInDebtCollection',
  NEGATIVE_CREDIT_STATUS_OVER_LIMIT: 'negativeCreditStatusOverLimit',
  FORMER_CUSTOMER_SOFT_COLLECTION_PROCEEDING: 'formerCustomerSoftCollectionProceeding',
  EXISTING_LOAN_IN_DEBT: 'existingLoanInDebt',
  REQUIRED_SPOUSE_NOT_INCLUDED: 'requiredSpouseNotIncluded',
  CUSTOMER_INCOME_CONFIRMATION_NEEDED: 'customerIncomeConfirmationNeeded',
  REMAINING_PRINCIPAL_IN_BIGBANK_OVER_LIMIT: 'remainingPrincipalInBigbankOverLimit',
  JOINT_REMAINING_PRINCIPAL_IN_BIGBANK_OVER_LIMIT: 'jointRemainingPrincipalInBigbankOverLimit',
  SENIORITY_TOO_SHORT: 'seniorityTooShort',
  JOINT_NET_INCOME_UNDER_MIN: 'jointNetIncomeUnderMin',
  NET_INCOME_UNDER_MIN_INDIVIDUAL: 'netIncomeUnderMinIndividual',
  COMPANY_PERIOD_TOO_SHORT: 'companyPeriodTooShort',
  SENIORITY_SELF_EMPLOYED_TOO_SHORT: 'senioritySelfEmployedTooShort',
  ACTIVE_EXTERNAL_SIGNIFICANT_DEBT_FINANCIAL: 'activeExternalSignificantDebtFinancial',
  ACTIVE_EXTERNAL_OVERDUE_DEBT_OVER_LIMIT: 'activeExternalOverdueDebtOverLimit',
  ACTIVE_EXTERNAL_SIGNIFICANT_DEBT_NON_FINANCIAL: 'activeExternalSignificantDebtNonFinancial',
  CUSTOMER_DTI_OVER_LIMIT: 'customerDtiOverLimit',
  JOINT_DTI_OVER_LIMIT: 'jointDTIOverLimit',
  JOINT_RESERVE_NEGATIVE: 'jointReserveNegative',
  CUSTOMER_RESERVE_NEGATIVE: 'customerReserveNegative',
  APPLICATION_REQUIRES_SPOUSE: 'applicationRequiresSpouse',
  HOUSEHOLD_REQUIRES_SPOUSE: 'householdRequiresSpouse',
  REFINANCING_PRODUCT_NOT_POSSIBLE: 'refinancingProductNotPossible',
  CUSTOMER_IS_DEAD: 'customerIsDead',
  CUSTOMER_IS_SANCTIONED: 'customerIsSanctioned',
  CUSTOMER_IS_BLOCKED: 'customerIsBlocked',
  CUSTOMER_IS_INCAPABLE: 'customerIsIncapable',
  FREQUENT_POSITIVE_DECISION: 'frequentPositiveDecision',
  TEMPORARY_LIVING_PERMIT: 'temporaryLivingPermit',
};

export const LIABILITY = {
  mortgage: 'MORTGAGE',
  consumerLoan: 'CONSUMER_LOAN',
  other: 'OTHER',
};

export const PRODUCT_NAME = {
  SMALL_LOAN: 'SMALL_LOAN',
  REFINANCING_LOAN: 'REFINANCING_LOAN',
  CAR_LOAN: 'CAR_LOAN',
  RENOVATION_LOAN: 'RENOVATION_LOAN',
  MEDICAL_LOAN: 'MEDICAL_LOAN',
  REG_HIRE_PURCHASE: 'REG_HIRE_PURCHASE',
  CAR_HIRE_PURCHASE: 'CAR_HIRE_PURCHASE',
  COMP_HIRE_PURCHASE: 'COMP_HIRE_PURCHASE',
  CAR_FINANCE_LEASE: 'CAR_FINANCE_LEASE',
  HOUSING_LOAN: 'HOUSING_LOAN',
};

export const HIRE_PURCHASE_PRODUCT_NAMES = [
  PRODUCT_NAME.CAR_HIRE_PURCHASE,
  PRODUCT_NAME.REG_HIRE_PURCHASE,
  PRODUCT_NAME.COMP_HIRE_PURCHASE,
];

export const PRODUCT_REGISTRY = {
  CAR_LOAN: 'Car loan',
  RENOVATION_LOAN: 'Renovation loan',
  SMALL_LOAN: 'Small loan',
  MEDICAL_LOAN: 'Medical loan',
  REFINANCING_LOAN: 'Refinancing loan',
  REGULAR_HIRE_PURCHASE: 'Regular hire purchase',
  CAR_HIRE_PURCHASE: 'Car hire purchase',
  PARTNERS_COMPENSATED_HIRE_PURCHASE: 'Partners compensated regular hire purchase',
  CAR_FINANCE_LEASE: 'Car finance lease',
  HOUSING_LOAN: 'Housing loan',
};

export const PRODUCT_TYPE = {
  SMALL_LOAN_LT01: 'SMALL_LOAN_LT01',
  RENOVATION_LOAN_LT01: 'RENOVATION_LOAN_LT01',
  MEDICAL_LOAN_LT01: 'MEDICAL_LOAN_LT01',
  REFINANCING_LOAN_LT01: 'REFINANCING_LOAN_LT01',
  CAR_LEASING_PRIVATE_LT01: 'CAR_LEASING_PRIVATE_LT01',
  CAR_LOAN_LT01: 'CAR_LOAN_LT01',
  CAR_HIRE_PURCHASE_LT01: 'CAR_HIRE_PURCHASE_LT01',
  REG_HIRE_PURCHASE_LT01: 'REG_HIRE_PURCHASE_LT01',
  COMP_HIRE_PURCHASE_LT01: 'COMP_HIRE_PURCHASE_LT01',
  CAR_LEASE_LT04: 'CAR_LEASE_LT04',
  HOUSING_LOAN_LT01: 'HOUSING_LOAN_LT01',
};

export const LOAN_PURPOSES_FOR_REAL_ESTATE = [
  LOAN_PURPOSE.PURCHASING_HOME,
  LOAN_PURPOSE.PURCHASING_OTHER_REAL_ESTATE,
  LOAN_PURPOSE.PURCHASING_RENTAL_PROPERTY,
  LOAN_PURPOSE.BUILDING_HOME,
  LOAN_PURPOSE.BUILDING_RENTAL_PROPERTY,
  LOAN_PURPOSE.BUILDING_OTHER_REAL_ESTATE,
];

export const LOAN_PURPOSES_FOR_RENOVATION = [
  LOAN_PURPOSE.RENOVATING_HOME,
  LOAN_PURPOSE.PURCHASING_HOME,
  LOAN_PURPOSE.PURCHASING_OTHER_REAL_ESTATE,
  LOAN_PURPOSE.PURCHASING_RENTAL_PROPERTY,
  LOAN_PURPOSE.BUILDING_HOME,
  LOAN_PURPOSE.BUILDING_OTHER_REAL_ESTATE,
  LOAN_PURPOSE.BUILDING_RENTAL_PROPERTY,
];

export const LOAN_PURPOSES_FOR_HOUSING_LOAN = [
  LOAN_PURPOSE.PURCHASING_HOME,
  LOAN_PURPOSE.PURCHASING_RENTAL_PROPERTY,
  LOAN_PURPOSE.PURCHASING_OTHER_REAL_ESTATE,
  LOAN_PURPOSE.BUILDING_HOME,
  LOAN_PURPOSE.BUILDING_RENTAL_PROPERTY,
  LOAN_PURPOSE.BUILDING_OTHER_REAL_ESTATE,
  LOAN_PURPOSE.COMBINING_LOANS,
];

export const SEGMENT = { PREMIUM: 'PREMIUM' };

export const DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED = 20;
export const APPLICATION_OFFER_TYPE_UPSALE = 'UPSALE';
export const APPLICATION_OFFER_DECISION_APPROVED = 'Approved';
export const OFFER_OPTION_CUSTOM = 'OFFER_OPTION_CUSTOM';
export const OFFER_OPTION_DEFAULT = 'OFFER_OPTION_DEFAULT';
export const OFFER_OPTION_MAX = 'OFFER_OPTION_MAX';

export const ROLE = {
  CO_APPLICANT: 'coApplicant',
  APPLICANT: 'applicant',
};

export const PERSON = {
  SPOUSE: 'spouse',
  APPLICANT: 'applicant',
  CO_BORROWER: 'coBorrower',
};

export const IDENTIFICATION_STATUS = {
  RUNNING: 'RUNNING',
  COMPLETED_OK: 'COMPLETED_OK',
  COMPLETED_FAILURE: 'COMPLETED_FAILURE',
  TECHNICAL_ERROR: 'TECHNICAL_ERROR',
  NO_STATUS: 'NO_STATUS',
};

export const INTEREST_TYPE = {
  FIXED: 'FIX',
  EURIBOR_6_MONTHS: '6m',
};

export const DOCUMENT_RESOLUTION = {
  VALID: 'VALID',
  INSUFFICIENT: 'INSUFFICIENT',
  FRAUDULENT: 'FRAUDULENT',
  MISSING: 'MISSING',
  NOT_REQUIRED: 'NOT_REQUIRED',
  CANNOT_PROVIDE: 'CANNOT_PROVIDE',
};

export const MAX_NUM_VALUE = 999999; // This value is governed by IC.
export const MAX_PERIOD_VALUE = 999;

export const DOWNLOAD_DOCUMENT_TYPE_LEASING = 'leasing';
export const DOWNLOAD_DOCUMENT_TYPE_INCOME = 'income';
export const DOWNLOAD_DOCUMENT_TYPES = [
  DOWNLOAD_DOCUMENT_TYPE_LEASING,
  DOWNLOAD_DOCUMENT_TYPE_INCOME,
];

export const MIN_ALLOWED_EXTRA_OPTION_PERIOD = 60;

export const PAYMENT_DAY_OPTIONS = [
  {
    text: '1',
    value: 1,
  },
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '15',
    value: 15,
  },
  {
    text: '17',
    value: 17,
  },
  {
    text: '20',
    value: 20,
  },
  {
    text: '25',
    value: 25,
  },
];
