<template>
  <bb-modal
    id="identification-modal"
    center-vertically
    :visible="showModal"
    :public-size="isBankLinkType ? 'l' : 'm'"
    flavor="user"
    :hide-close="isPartnerApiFlow"
    :static-overlay="isPartnerApiFlow"
    @close="closeReset"
  >
    <bank-link-error
      v-if="bankLinkErrorCode"
      :error-code="bankLinkErrorCode"
      action="identification"
      @cancel="bankLinkErrorCode = null"
    />

    <loading v-show="showLoader" />

    <identification-methods
      v-show="showIdentificationMethods"
      @click="onClickMethod"
    />

    <!--    <bank-instructions-->
    <!--      v-show="showBankInstructions"-->
    <!--      :method="chosenType"-->
    <!--      @click="onClickContinueBankLink"-->
    <!--    />-->
  </bb-modal>
</template>

<script>
  import { modalMixin } from '@/mixins/modalMixin';
  import IdentificationMethods from './IdentificationMethods';
  import { mapActions, mapGetters } from 'vuex';
  import { METHOD_KINDS, VERIFICATION_TYPES } from '@/constants/verification';
  import Loading from '../Loading';
  // import BankInstructions from '../signing/BankInstructions';
  import BankLinkError from './BankLinkError';
  import partnerApiMixin from '../../mixins/partnerApiMixin';

  export default {
    name: 'identification-modal',
    components: {
      IdentificationMethods,
      Loading,
      // BankInstructions,
      BankLinkError,
    },
    mixins: [
      modalMixin,
      partnerApiMixin,
    ],
    data() {
      return {
        chosenType: null,
        VERIFICATION_TYPES,
        bankLinkErrorCode: null,
      };
    },
    computed: {
      ...mapGetters('identification', ['isLoading']),
      ...mapGetters('processStep', { 'isLoadingProcessStep': 'isLoading' }),
      ...mapGetters('products', { 'isLoadingProducts': 'isLoading' }),
      showLoader() {
        return !this.bankLinkErrorCode && (this.isLoading || this.isLoadingProcessStep || this.isLoadingProducts);
      },
      showIdentificationMethods() {
        return !this.bankLinkErrorCode && !this.showLoader && this.chosenType === null;
      },
      // Disabled until LOVE-1970.
      // showBankInstructions() {
      //   return !this.bankLinkErrorCode && !this.showLoader && this.isBankLinkType;
      // },
      isBankLinkType () {
        return this.chosenType?.startsWith(METHOD_KINDS.BANKLINK);
      },
    },
    async mounted() {
      this.open();
      if (this.$route?.query?.errorCode) {
        this.bankLinkErrorCode = this.$route?.query?.errorCode;
        delete this.$route.query.errorCode;
      }
    },
    methods: {
      ...mapActions('identification', [
        'login',
        'authenticate',
      ]),
      onClickMethod(type) {
        if ([
          VERIFICATION_TYPES.smart_id,
          VERIFICATION_TYPES.mobile_id,
          VERIFICATION_TYPES.bigbank_id,
        ].includes(type)) {
          this.login(type);
        } else {
          this.chosenType = type;
          this.onClickContinueBankLink();
        }
      },
      cancelChosenType() {
        this.chosenType = null;
      },
      async onClickContinueBankLink() {
        await this.authenticate(this.chosenType);
      },
      closeReset() {
        this.close();
        setTimeout(this.cancelChosenType, 300);
      },
    },
  };
</script>
